.about-container {
    margin-top: 20px;
    margin-right: 30px;

    @media screen and (max-width: 720px) {
        margin-top: 15px;
    }
}

.space {
    margin-top: 30px;
}