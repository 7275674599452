.content-contact {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    margin-right: 100px;
    overflow: hidden;

    @media screen and (max-width: 720px) {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        margin-right: 0;
    }
}

.contact-header {
    margin-top: 100px;

    @media screen and (max-width: 720px) {
        margin-top: 30px;
    }
}

.form-contact {
    max-width: 650px;

    @media screen and (max-width: 720px) {
        margin-top: 30px;
    }
}

.contact-left {
    max-width: 300px;
}


.contact-icon-title {
    display: flex;
    padding-top: 10px;
}

.text {
    padding-left: 10px;
}