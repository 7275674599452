.content-home {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;

    @media screen and (max-width: 720px) {
        flex-direction: column;
        margin-top: 30px;
    }
}

.content {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    @media screen and (max-width: 720px) {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
    }
}

.image-gallery {
    width: 650px;
    margin-left: 30px;
    font-weight: bold;

    @media screen and (max-width: 720px) {
        max-height: 300px;
        margin-left: 0px;
        width: auto;
        margin-top: 10px;
    }
}

.home-bottom {
    color: white;
    margin-top: 100px;
    margin-bottom: 50px;
    /* height: 300px; */
    background-color: #212529;
    align-items: center;
    border-radius: 10px;

    @media screen and (max-width: 720px) {
        margin-top: 30px;
    }
}

.tab-container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 50px;

    @media screen and (max-width: 720px) {
        flex-direction: column;
        align-items: center;
    }
 }

.tab-text {
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 10px;
    text-align: center;
}

.tab-list {
    font-size: 13px;
    display: flex;
    justify-content: center;
}

.tab-item {
    width: 200px;

    @media screen and (max-width: 720px) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
}

.home-button {
    display: flex;
    justify-content: center;
    padding-top: 65px;
    padding-bottom: 20px;
}